<template>
  <div class="index-news-component-container">
    <div class="index-news-component-container-left">
        <div :class="showall ? 'small-img-show' : 'small-img'">
            <img src="@/assets/index/news-small-bg.svg" alt="">
        </div>
        <div class="big-img">
            <img src="@/assets/index/news.png" alt="">
        </div>
    </div>
    <div class="index-news-component-container-right">
        <div class="new-title">
          <div class="news-title-line"></div>
          <div class="news-title-ch">新闻动态</div>
          <div class="news-title-en">NEWS</div>
        </div>
        <div class="list">
          <div class="top-news-card" 
                v-for="item in topNewsList"
                :key="item.id">
            <div class="top-news-card-left">
              <div class="top-news-card-left-title"  @click="toNewsDetails(item.id)">{{ item.title}}</div>
              <div class="top-news-card-left-content">{{ item.subtitle}}</div>
            </div>
            <div class="top-news-card-right">
              <div class="top-news-card-right-day">{{item.date.split('-')[2]}}</div>
              <div class="top-news-card-right-year">{{item.date.split('-')[0]}}-{{item.date.split('-')[1]}}</div>
              <div class="top-news-card-right-button" @click="toNewsDetails(item.id)">
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>
          <div class="news-list-option" @click="toNewsDetails(item.id)" v-for="item in bottomNewsList" :key="item.id">
            <div class="title">{{item.title}}</div>
            <div class="date">{{item.date}}</div>
          </div>
        </div>
        <div class="news-more-button" @click="handleToNewsList">
            <img src="@/assets/index/more.png" alt="">
        </div>
    </div>
  </div>
</template>

<script>
export default {
   data () {
    return {
        newsList: [],
        topNewsList: [],
        bottomNewsList: [],
        showall: false // 是否展示动画效果
    }
   },
   created () {
    this.getNewsList()
   },
   mounted () {
    window.addEventListener('scroll', ()=>{
      if (window.scrollY > 700 &&  window.scrollY < 2100) {
        this.showall = true
      } else {
        this.showall = false
      }
    })
   },
   methods:{
     // 获取新闻列表
     getNewsList () {
        this.$Api.getNewsList().then((res) => {
          if (res.code === '00000') {
            this.newsList = res.data
            this.topNewsList = this.newsList.slice(0,1)
            this.bottomNewsList = this.newsList.slice(1,6)
          } 
        })
      },
       // 前往新闻详情
       toNewsDetails (id) {
        this.$router.push({
          name:'news_details',
          query: { id }
        })
      },
      // 前往新闻详情
      handleToNewsList () {
        this.$router.push('news')
      },
   }
}
</script>

<style lang="scss">
.index-news-component-container{
  width: 1200px;
  margin: auto; 
  margin-top: 98px;
  display: flex;
  justify-content: space-between;
  .index-news-component-container-left{
    position: relative;
    width: 669px;
    height: 543px;
    .small-img{
      width: 0;
      height: 325px;
      transition: all 2s;
      img{
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .small-img-show{
      width: 535px;
      height: 325px;
      transition: all 2s;
      img{
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .big-img{
        position: absolute;
        bottom:0;
        right: 0;
        width: 588px;
        height: 431px;
        img{
            display: block;
            width: 100%;
            height: 100%;
        }
    }
  }
  .index-news-component-container-right{
    margin-right: 111px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    .new-title{
      width: 100%;
      .news-title-line{
        width: 48px;
        height: 7px;
        background: #51A1A5;
      }
      .news-title-ch{
        margin-top: 9px;
        font-size: 38px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #070707;
      }
      .news-title-en{
        margin-top: 24px;
        font-size: 23px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #4D4D4D;
      }
    }
      .list{
        .top-news-card{
            width: 357px;
          // height: 133px;
          margin-top: 21px;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px dashed #4D4D4D;
          .top-news-card-left{
            padding-bottom: 18px;
            .top-news-card-left-title{
              cursor: pointer;
              width: 268px;
              font-size: 19px;
              font-family: Adobe Heiti Std;
              font-weight: normal;
              color: #54A4A1;
            }
            .top-news-card-left-content{
              width: 268px;
              height: 85px;
              overflow: hidden;
              display: -webkit-box;//谷歌
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 4;//显示几行
              overflow: hidden;
              margin-top: 20px;
              font-size: 11px !important;
              font-family: Adobe Heiti Std;
              font-weight: normal;
              color: #676767;
              p{
                height: 85px;
                overflow: hidden;
                display: -webkit-box;//谷歌
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 4;//显示几行
                overflow: hidden;
                color: #676767 !important;
                font-size: 11px !important;
                line-height: 22px !important;
                margin: 0 !important;
                span{
                  color: #676767 !important;
                  background: none !important;
                  text-decoration: none !important;
                  font-size: 11px !important;
                  em{
                    font-style: normal !important;
                    font-size: 11px !important;
                  }
                  strong{
                    font-weight: 500;
                    font-size: 11px !important;
                  }
                }
              }
            }
          }
          .top-news-card-right{
            color: #54A4A1;
            .top-news-card-right-day{
              font-size: 31px;
              font-family: Adobe Heiti Std;
              font-weight: normal;
            }
            .top-news-card-right-year{
              margin-top: 18px;
              font-size: 11px;
              font-family: Adobe Heiti Std;
              font-weight: normal;
            }
            .top-news-card-right-button{
              cursor: pointer;
              margin-top: 15px;
              font-size: 39px;
              font-family: Adobe Heiti Std;
              font-weight: normal;
            }
            
          }

        }
        .news-list-option{
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 357px;
          height: 48px;
          line-height: 48px;
          border-bottom: 1px dashed #4D4D4D;
          .title{
            width: 282px;
            font-size: 16px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #4D4D4D;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .date{
            font-size: 11px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #4D4D4D;
          }
        }
      }
      .news-more-button{
          width: 106px;
          height: 28px;
          margin-top: 28px;
          img{
            display: block;
            width: 106px;
            height: 28px;
          }
        }
  }
}
</style>