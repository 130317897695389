<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  data () {
    return {}
  },
  created () {
    this.$store.dispatch('getMenuList')
  },
  methods:{

  }
}
</script>
<style lang="scss">
#app{
  min-width: 1200px;
}
</style>
