import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Api from './api/api'
import 'animate.css';
import store from './store/index'
// import  "amfe-flexible";
import 'normalize.css'
import 'element-ui/lib/theme-chalk/index.css'
import { Message, pagination, Collapse, CollapseItem } from 'element-ui';
// import css from 'swiper/css/swiper.css' // 注意css路径
// import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(pagination)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.config.productionTip = false
Vue.prototype.$Api = Api
Vue.prototype.$message = Message
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
