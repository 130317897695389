<template>
  <div class="header-container">
    <div class="header-inner-container">
      <div class="logo" @click="handleToIndex()">
          <img src="@/assets/common/logo.jpg" alt="">
          <span>众驰医疗</span>
      </div>
      <div class="right">
          <div class="tabs">
                <div  v-for="(item, index) in list"
                :key="index"
                @click="changeTab(item.to)"
                @mouseenter="handleMouseEnterTab(item.to)"
                @mouseleave="handleMouseLeaveTab()"
                :class="item.pathes.includes($route.path)? 'tab active' :'tab'" >
                 <div>{{item.tabTitle}}</div>
                 <div class="submenu"
                      v-show="(item.to === hoverTabPath)"
                      v-if="(item.subMenuList.length > 0)">
                    <div v-for="(item2,index2) in item.subMenuList" 
                         :key="index2"
                         class="submenu-item"
                         @click.stop="toOtherPage(index,item2.id)"
                         :class="(hoverSubmenuTabTitle === item2.value ? 'submenu-item-active' : '')"
                         @mouseenter.stop="handleMouseEnterSubmenuTab(item2.value)"
                         @mouseleave.stop="handleMouseLeaveSubmenuTab('')"
                         >{{item2.value}}</div>
                 </div>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      hoverTabPath: '', // 鼠标选中导航栏索引
      hoverSubmenuTabTitle: '', // 鼠标选中的二级菜单标题
    }
  },
  computed: mapState({
    // 箭头函数可使代码更简练
    list: state => state.dictionary.list,
  }),
  methods: {
    // 鼠标移入对应导航栏
    handleMouseEnterTab (path) {
      this.hoverTabPath = path
    },
    // 鼠标移出对应导航栏
    handleMouseLeaveTab () {
      this.hoverTabPath = ''
    },
    // 鼠标移入对应二级导航栏
    handleMouseEnterSubmenuTab (title) {
      this.hoverSubmenuTabTitle = title
    },
    // 鼠标移出二级导航栏
    handleMouseLeaveSubmenuTab () {
      this.hoverSubmenuTabTitle = ''
    },
    // 前往首页
    handleToIndex () {
      this.$router.push('/')
    },
    // 切换导航
    changeTab (url) {
      this.$router.replace(url)
    },
    // 新增部分
    
        // 前往关于我们页面
        toAboutUsPage (id) {
      this.$router.push({
        path:'/about',
        query:{id}
      })
    },
    // 前往新闻动态页面
    toNewsPage (id) {
      this.$router.push({
        path:'/news',
        query:{id}
      })
    },
    // 前往产品列表页面
    toProductPage (id) {
      this.$router.push({
        path:'/product',
        query:{id}
      })
    },
    //前往售后服务页面
    toServicePage (id) {
      this.$router.push({
        path:'/service',
        query:{id}
      })
    },
    // 前往人力资源页面
    toRecruitPage () {
      this.$router.push({
        path:'/recruit'
      })
    },
    // 前往联系我们页面
    toContactPage() {
      this.$router.push({
        path:'/contact'
      })
    },
    // v1.0.1新增
    toRecruitPage(id) {
      this.$router.push({
        path:'/recruit',
        query:{id}
      })
    },
    toOtherPage(index, id) {
      if (index === 0) {
        this.toAboutUsPage(id)
      } else if (index === 1) {
        this.toProductPage(id)
      } else if (index === 2) {
        this.toServicePage(id)
      } else if (index === 3) {
        this.toNewsPage(id)
      } else if (index === 4) {
        this.toRecruitPage(id)
      } else if (index === 5) {
        this.toContactPage()
      }
    },
  }
}
</script>

<style lang="scss">
.header-container{
    position: relative;
    width: 100%;
    height: 104px;
    z-index: 999;
    .header-inner-container{
      width: 1200px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      background: #FFFFFF;
      .logo{
        display: flex;

        align-items: center;
        // width: 60px;
        // height: 86px;
        margin-top: 10px;
        margin-left: 18px;
        img{
          cursor: pointer; 
          display: block;
          width: 60px;
          height: auto;
        }
        span{
          margin-top: 20px;
          margin-left: 20px;
          font-size: 26px;
        }
      }
    .right{
      display: flex;
      margin-right: 77px;
      .tabs{
        width: 673px;
        height: 51px;
        display: flex;
        justify-content: space-between;
        margin-right: 79px;
        margin-top: 53px;
        .tab{
          position: relative;
          cursor: pointer;
          font-size: 17px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #000000;
          text-decoration: none;
          &:hover{
            color: #55A6AB;
            &::after{
              display: block;
              content: ' ';
              width: 39px;
              height: 3px;
              margin: auto;
              margin-top: 12px;
              background: linear-gradient(90deg, rgba(80,163,156,0.89), rgba(255,255,255,0));
              border-radius: 2px;
            }
          }
          .submenu{
            position: absolute;
            width: 234px;
            // height: 117px;
            padding: 12px 0;
            margin-left: -100%;
            margin-top: 32px;
            background: #FFFFFF;
            z-index: 9999;
            .submenu-item{
              width: 175px;
              height: 46px;
              line-height: 46px;
              margin: auto;
              border-bottom:  1px solid #C7C7C7;
              font-size: 16px;
              font-weight: normal;
              color: #242728;
              &:last-child{
                border: none;
              }
            }
            .submenu-item-active{
              color: #51A1A5;
            }

          }
        }
        .active{
            color: #55A6AB;
            &::after{
              display: block;
              content: ' ';
              width: 39px;
              height: 3px;
              margin: auto;
              margin-top: 12px;
              background: linear-gradient(90deg, rgba(80,163,156,0.89), rgba(255,255,255,0));
              border-radius: 2px;
            }
        }
      }
    }
    }
}
</style>