<template>
  <div class="index-page-aboutus-container">
     <div class="title-ch">关于我们</div>
     <div class="title-en">ABOUT US</div>
     <div class="about-us-section">
        <div class="about-us-section-left">
           <div class="about-us-section-left-title">众驰集团 / zhongchi group</div>
           <div class="about-us-section-left-tip" v-if="info.content">{{ info.content}}</div>
           <div class="about-us-section-left-options">
            <div class="option-item">
                <div class="option-item-top" v-if="info.year">{{info.year}}<span>年</span></div>
                <div class="option-item-bot">成立于</div>
            </div>
            <div class="line"></div>
            <div class="option-item">
                <div class="option-item-top" v-if="info.partner">{{info.partner}}<span>位</span></div>
                <div class="option-item-bot">合作伙伴</div>
            </div>
            <div class="line"></div>
            <div class="option-item">
                <div class="option-item-top" v-if="info.staff">{{info.staff}}<span>名</span></div>
                <div class="option-item-bot">在职员工</div>
            </div>
           </div>
           <div class="see-more-button" @click="handleSeeMoreAboutUs">查看更多</div>  
        </div>
        <div class="about-us-section-right">
            <img v-if="info.image" :src="info.image" alt="">
        </div>
     </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      info:{
        content: '',
        year:'',
        partner: '',
        staff:'',
        image: ''
      }  
    }
  },
  created () {
    this.getIndexSettingInfo()
  },
  methods:{
    getIndexSettingInfo () {
        this.$Api.getIndexSettingInfo().then((res) => {
            if (res.code === '00000') {
                this.info = res.data 
            }
        })
    },
    // 关于我们查看更多
    handleSeeMoreAboutUs () {
      this.$router.push('about')
    }
  }
}
</script>

<style lang="scss">
.index-page-aboutus-container{
    width: 1200px;
    margin: auto;
    margin-top: 92px;
    margin-bottom: 93px;
    .title-ch{
        font-size: 34px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #070707;
        text-align: center;
    }
    .title-en{
        margin-top: 16px;
        font-size: 21px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #4D4D4D;
        text-align: center;
    }
    .about-us-section{
        display: flex;
        justify-content: space-between;
        margin-top: 43px;
        .about-us-section-left{
            width: 484px;
            height: 338px;
            margin-left: 108px;
            .about-us-section-left-title{
                margin-top: 15px;
                font-size: 28px;
                font-family: Adobe Heiti Std;
                font-weight: normal;
                color: #070707;
            }
            .about-us-section-left-tip{
                overflow: hidden;
                margin-top: 41px;
                width: 431px;
                font-size: 17px;
                font-family: Adobe Heiti Std;
                font-weight: normal;
                color: #7D7E80;
                line-height: 21px;
                display: -webkit-box;//谷歌
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 4;//显示几行
            }
            .about-us-section-left-options{
                margin-top: 28px;
                width: 431px;
                display: flex;
                justify-content: space-between;
                .option-item{
                  .option-item-top{
                    font-size: 34px;
                    font-family: Adobe Heiti Std;
                    font-weight: normal;
                    color: #010101;
                    span{
                      font-size: 11px;
                      font-family: Adobe Heiti Std;
                      font-weight: normal;
                      color: #010101;
                    }
                  }
                  .option-item-bot{
                    margin-top: 11px;
                    font-size: 11px;
                    font-family: Adobe Heiti Std;
                    font-weight: normal;
                    color: #7D7E80;
                  }
                }
                .line{
                    width: 1px;
                    height: 29px;
                    background: #969799;
                }
            }
            .see-more-button{
              cursor: pointer;
              width: 121px;
              height: 45px;
              margin-top: 20px;
              line-height: 45px;
              text-align: center;
              background: #51A1A5;
              color: #FFF;
              border: 1px solid #FFFFFF;
              font-size: 18px;
              font-family: Adobe Heiti Std;
              font-weight: normal;
            }
        }
        .about-us-section-right{
            margin-right: 108px;
            width: 497px;
            height: 338px;
            img{
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>