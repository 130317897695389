<template>
  <div class="index-banner-container">
    <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in bannerList" :key="index">
            <img :src="item.url" alt="">
            <div class="banner-handle-section">
              <div class="banner-title" v-show="!isChange">
                <div class="animate__animated animate__slideInUp">{{item.title}}</div>
              </div>
              <div class="banner-sub-text" v-show="!isChange">
                <div class="animate__animated animate__slideInUp">{{item.tip}}</div>
              </div>
              <div v-if="item.jump" @click="goToOtherPage(item.jump)" class="banner-handle-button">了解更多</div>
            </div>
        </div>
    </div>
    <div class="swiper-pagination"></div>
  </div>
</template>

<script>
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import Swiper from "swiper"
export default {
  data () {
    return {
      bannerList: [], // 轮播图列表  
      currentBannerIndex: 0, // 当前轮播图命中的索引
      showText: true,
      isChange: false, // 是否正在切换
    }
  },
  created () {
    this.getBannerList()
  },
  mounted () {
    const that = this
    setTimeout(() => {
          new Swiper('.index-banner-container', {
            loop: true,
            loopAdditionalSlides: this.bannerList.length,
            autoplay: {
              delay: 6000,
            },
            on:{
              slideChangeTransitionStart: () => {
               this.isChange = true
              },
              slideChangeTransitionEnd: () => {
                setTimeout(() => {
                  this.isChange = false
                }, 0);
              },
            },
              // 如果需要分页器
            pagination: {
              el: '.swiper-pagination',
              clickable: true, // 分页器可以点击
            },
          })
        }, 1000);
  },
  methods: {
    // 获取轮播图列表
    getBannerList () {
      this.$Api.getBannerList().then((res) => {
        if (res.code === '00000') {
          this.bannerList = res.data
        } 
     })
    },
    // 点击轮播图前往页面
    goToOtherPage (url) {
      window.location.href = url
    }
  }
}
</script>

<style lang="scss">
  .index-banner-container{
    position: relative;
    width: 100%;
    min-width: 1200px;
    height: calc( 100vh - 109px );
    overflow: hidden;
    img{
      display: block;
      width: 100%;
      height: calc( 100vh - 109px );
      z-index: 0;
    }
    .banner-handle-section{
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      z-index: 999;
      background: rgba($color: #242728, $alpha: 0);
      color: #000;
      .banner-title{
        user-select:none;
        height: 90px;
        margin-top: 250px;
        margin-left: 99px;
        overflow: hidden;
        font-size: 84px;
        font-weight: normal;
        font-family: Adobe Heiti Std;
        color: #FFF;
      }
      .banner-sub-text{
        user-select:none;
        height: 26px;
        margin-top: 28px;
        margin-left: 99px;
        overflow: hidden;
        font-size: 24px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #FFF;
      }
      .banner-handle-button{
        cursor: pointer;
        width: 129px;
        height: 49px;
        line-height: 49px;
        text-align: center;
        margin-top: 60px;
        margin-left: 99px;
        border: 1px solid #FFFFFF;
        font-size: 18px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #FFF;
      }

    }
    .swiper-pagination-bullet{
      z-index: 1888;
      position: relative;
      width: 63px;
      height: 1px;
      background: #FFFFFF;
    }
    .swiper-pagination-bullet-active{
      z-index: 1888;
      position: relative;
      width: 147px;
      height: 1px;
      background: #51A1A5;
      transition:width 1s;
    }
  }
</style>