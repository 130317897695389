<template>
  <div class="footer-container">
    <div class="footer-innner-container">
      <div class="footer-top">
        <div class="informations">
          <div class="information-option">
            <span class="title">服务电话：</span>
            <span>0431-85555859 / 17519281777</span>
          </div>
          <div class="information-option">
            <span class="title">电子邮箱：</span>
            <span>zhongchimedical@126.com</span>
          </div>
        </div>
        <div class="qr-code-container">
          <div class="qr-code">
            <img src="../assets/common/qrcode.jpg" />
          </div>
          <div class="title">关注微信公众号</div>
        </div>
      </div>
      <div class="footer-bot">
        <div class="left">版权所有：吉林省众驰医疗科技有限公司</div>
        <div class="right">技术支持：吉林省有渔互动科技有限公司</div>
        <div class="right">备案号: 吉ICP备2022006662号</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">
.footer-container {
  width: 100%;
  // height: 392px;
  overflow: hidden;
  background-color: #273341;
  .footer-innner-container {
    width: 1200px;
    // height: 392px;
    margin: auto;
    background: #273341;
    .footer-top {
      padding-top: 86px;
      display: flex;
      justify-content: space-between;
      background-size: 1200px;
      background-repeat: no-repeat;
      background-image: url("@/assets/common/footer.png");
      .informations {
        overflow: hidden;
        margin-top: 36px;
        padding-bottom: 18px;
        // display: flex;
        font-size: 20px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #ffffff;
        border-bottom: 1px solid #fafafa;
        .information-option {
          margin-left: 70px;
          margin-bottom: 10px;
          font-size: 15px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #87c7c4;
          .title {
            color: #ffffff;
            font-size: 13px;
          }
        }
      }
      .qr-code-container {
        width: 151px;
        margin-right: 84px;
        .qr-code {
          width: 151px;
          height: 151px;
          // margin-right: 134px;
          background: #fafafa;
          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
        .title {
          text-align: center;
          margin-top: 18px;
          font-size: 14px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #ffffff;
        }
      }
    }
    .footer-bot {
      display: flex;
      font-size: 11px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #ffffff;
      .left {
        margin-left: 71px;
        margin-top: 18px;
        margin-bottom: 18px;
      }
      .right {
        margin-left: 48px;
        margin-top: 18px;
        margin-bottom: 18px;
      }
    }
  }
}
</style>