import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/contact.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "about" */ '../views/news.vue')
  },
  {
    path: '/news_details',
    name: 'news_details',
    component: () => import(/* webpackChunkName: "about" */ '../views/news_details.vue')
  },
  {
    path: '/product_details',
    name: 'product_details',
    component: () => import(/* webpackChunkName: "about" */ '../views/product_details.vue')
  },
  {
    path: '/product',
    name: 'product',

    component: () => import(/* webpackChunkName: "about" */ '../views/product.vue')
  },
  {
    path: '/service',
    name: 'service',
    component: () => import(/* webpackChunkName: "about" */ '../views/service.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/about.vue')
  },
  {
    path: '/recruit',
    name: 'recruit',
    component: () => import(/* webpackChunkName: "about" */ '../views/recruit.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
