import axios from "./axios";
export default ({
    // 获取轮播图
    getBannerList () {
      return axios.get('/api/getBannerList')
    },
    // 获取产品类别列表
    getProductTypeList () {
      return axios.get('/api/product/getProductTypeList')
    },
    // 获取产品列表
    getProductList (data) {
      return axios.get('/api/product/getProductList',{params: data})
    },
    // 获取产品详情
    getProductDetails (data) {
      return axios.get('/api/product/getProductDetails',{params:data})
    },
    // 获取新闻类别列表
    getNewsType () {
      return axios.get('/api/news/getNewsType')
    },
    // 获取新闻列表
    getNewsList (data) {
      return axios.get('/api/news/getNewsList',{params:data})
    },
    // 获取新闻详情
    getNewsDetails (data) {
      return axios.get('/api/news/getNewsDetails',{params:data})
    },
    // 获取招聘岗位列表
    getRecruitList (data) {
        return axios.get('/api/recruit/getRecruitList',{params: data})  
    },
    // 获取关于我们类型
    getAboutUsDic () {
        return axios.get('/api/aboutUs/getAboutUsDic')  
    },
    // 获取关于我们详情
    getContentById (id) {
        return axios.get(`/api/aboutUs/getContentById?id=${id}`,)  
    },
    // 获取售后服务类别
    getServiceDic () {
      return axios.get('/api/service/getServiceDic')  
    },
    // 获取售后服务详情
    getServiceContentById (data) {
      return axios.get('/api/service/getServiceContentById', { params: data})  
    },
    // 添加留言
    addMessage (data) {
      return axios.post('/api/message/addMessage', data)
    },
    // 获取首页配置项列表
    getIndexSettingList (data) {
      return axios.get('/api/indexsetting/getIndexSettingList', { params: data})  
    },
    // 获取首页配置详情
    getIndexSettingInfo (data) {
      return axios.get('/api/indexsetting/getIndexSettingInfo', { params: data})  
    },
    // 根据路径获取页面配置项
    getPageSettingInfoByPath (data) {
      return axios.get('/api/pagesetting/getPageSettingInfoByPath', { params: data})  
    },
    // v1.0.1新增
    // 获取人力资源类别
    getRecruitTypeList (data) {
      return axios.get('/api/recruit/getRecruitTypeList', { params: data})  
    },
    getIndexProductList () {
      return axios.get('/api/product/getIndexProductList')  
    },
    // 获取联系我们数据字典列表
    getContactDocList () {
      return axios.get('/api/contact/getContactDocList')  
    },
  })