<template>
  <div class="index-page-container">
    <Header></Header>
    <Banner></Banner>
    <Product></Product>
    <News></News>
    <Aboutus></Aboutus>
    <Footer></Footer>
  </div>
</template>

<script>

import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Banner from './components/banner.vue';
import Product from './components/product.vue';
import News from './components/news.vue'
import Aboutus from './components/aboutus.vue'
export default {
  components: { Header, Footer, Banner, Product, News, Aboutus},
    name: "IndexPage",
    data () {
      return {
        // 所需数据
        productTypeList: [], // 产品类别列表
        activeProductType: '', // 选中产品类别
        productList: [], // 产品列表
        newsTypeList: [], // 新闻类型列表
        activeNewsType: '', // 选中的新闻类别
        newsList: [], // 新闻列表
        bignewsList: [], // 左侧大图新闻列表
        topNewsList: [], // 顶部新闻
        bottomNewsList: [], // 底部新闻
        indexSettingList: [], // 首页配置项目列表
        indexSettingCurrentIndex:0 // 首页配置项选中项目
      }
    },
    mounted () {
      window.scrollTo(0,0)
    },
    created () {
      // this.$store.dispatch('getMenuList')
    },
    methods:{
    }
}
</script>
<style lang="scss">
.index-page-container{
}
</style>
