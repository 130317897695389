import axios from 'axios'
import qs from 'qs'
import router from '../router/index'
// import { Toast } from 'vant'
axios.defaults.baseURL = '/api'

axios.defaults.withCredentials = true
axios.defaults.timeout = 60000 // 设置60秒超时时间
axios.defaults.headers = {
  'Client-Type': 'H5',
  'Content-Type': 'application/json; charset=UTF-8',
}

// http request 拦截器
axios.interceptors.request.use(
  config => {
    if (config.headers['Content-Type'] && config.headers['Content-Type'].indexOf('www') > -1) {
      config.data = qs.stringify(config.data)
    }
    if (sessionStorage.getItem('token')) {
      config.headers.authorization = `${sessionStorage.getItem('token')}`
    }
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

// http response 拦截器
axios.interceptors.response.use(
  response => {
    if (response.data.code === '00000') {
      return response.data
    } else if (response.data.code === '401'){
      router.replace('login')
    }
  },
  error => {
    // 请求错误处理
    if (error.response) {
      // Toast.fail('请求超时!')
    }
    else if (error.message.includes('timeout')) {
      // 判断请求异常信息中是否含有超时timeout字符串
      // Toast.fail('请求超时!')
      return Promise.reject(error) // reject这个错误信息
    } else {
      // Toast.fail('请求超时!')
    }
    // Toast.clear()
    return Promise.reject(error) // 返回接口返回的错误信息
  }
)

export default axios
