import api from '../../api/api'
const dictionary = {
    state: () => ({
      count: 0,
      list: [
        {
          pathes: ['/about'],
          to: '/about',
          tabTitle: '走进众驰',
          subMenuList:[]
        },
        {
          pathes: ['/product', '/product_details' ],
          to: '/product',
          tabTitle: '产品中心',
          subMenuList:[]
        }, 
        {
          pathes: ['/service'],
          to: '/service',
          tabTitle: '用户服务',
          subMenuList:[]
        }, {
          pathes: ['/news', '/news_details' ],
          to: '/news',
          tabTitle: '新闻资讯',
          subMenuList:[]
        },{
          pathes: ['/recruit'],
          to: '/recruit',
          tabTitle: '人才招聘',
          subMenuList:[]
        },{
          pathes: ['/contact'],
          to: '/contact',
          tabTitle: '联系我们',
          subMenuList:[]
        }
      ]
    }),
    mutations:{
      changeAboutUsDic (state, payload) {
      //  state.list[4].subMenuList = payload
      },
      changeNewsType (state, payload) {
        state.list[3].subMenuList = payload
      },
      changeProductTypeList (state, payload) {
        state.list[1].subMenuList = payload
      },
      changeServiceDic (state, payload) {
        state.list[2].subMenuList = payload
      },
      changeRecruitTypeList (state, payload) {
        state.list[4].subMenuList = payload
      }
    },
    actions: {
      getMenuList (store) {
        // 获取关于我们数据字典
        api.getAboutUsDic().then((res) => {
          if (res.code === '00000') {
            store.commit('changeAboutUsDic', res.data)
          }
        })
        // 获取新闻类别数据字典
        api.getNewsType().then((res) => {
          if (res.code === '00000') {

          } store.commit('changeNewsType', res.data)
        })
        // 获取产品类别数据字典
        api.getProductTypeList().then((res) => {
          if (res.code === '00000') {
            store.commit('changeProductTypeList', res.data)
          }
        })
        // 获取售后服务数据字典
        api.getServiceDic().then((res) => {
          if (res.code === '00000') {
            store.commit('changeServiceDic', res.data)
          }
        })
        // 获取人力资源数据字典
        api.getRecruitTypeList().then((res) => {
          if (res.code === '00000') {
            store.commit('changeRecruitTypeList', res.data)
          }
        })
      }
    }
}

export default dictionary