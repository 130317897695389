<template>
  <div class="index-product-component-container">
    <div class="left-section">
      <div class="title-line"></div>
      <div class="title-ch animate__animated animate__slideInUp">产品展示</div>
      <div class="title-en animate__animated animate__slideInUp">
        product display
      </div>
      <div
        class="more-button animate__animated animate__slideInUp"
        @click="handleToProduct"
      >
        了解更多
      </div>
    </div>
    <div class="right-section animate__animated animate__slideInUp">
      <div class="product-left-container" v-if="leftProductList.length > 0">
        <div class="option" @click="handleToProductDetails(item.id)" v-for="item in leftProductList" :key="item.id">
          <div class="img" :style="{ backgroundImage: `url(${item.images})` }">
          </div>
          <div class="info">
            <div class="info-bg"></div>
            <div class="info-title">{{ item.name }}</div>
            <div class="info-tip">{{ item.tip }}</div>
          </div>
        </div>
      </div>
      <div
        class="product-right-container"
        v-if="rightProductList.length> 0"
      >
      <div class="product-right-option"  
         v-for="item in rightProductList"
         @click="handleToProductDetails(item.id)"
        :key="item.id">
        <div class="img" :style="{ backgroundImage: `url(${item.images})` }">
        </div>
        <div class="info">
          <div class="info-bg"></div>
          <div class="info-title">{{ item.name }}</div>
          <div class="info-tip">{{ item.tip }}</div>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      indexProductList: [], // 首页产品列表
    };
  },
  created() {
    this.getIndexProductList();
  },
  computed: {
    // 左侧
    leftProductList() {
      let arr = [];
      if (this.indexProductList.length <= 3) {
        for (let i = 0; i < 2; i++) {
          arr.push(this.indexProductList[i]);
        }
      }
      return arr;
    },
    rightProductList() {
      let arr = [];
      if (this.indexProductList.length === 3) {
        arr.push(this.indexProductList[2]);
      }
      return arr;
    },
  },
  methods: {
    // 获取首页产品列表
    getIndexProductList() {
      this.$Api.getIndexProductList().then((res) => {
        if (res.code === "00000") {
          this.indexProductList = res.data.list;
        }
      });
    },
    // 前往产品中心
    handleToProduct() {
      this.$router.push("product");
    },
    // 前往产品详情
    handleToProductDetails (id) {
      this.$router.push({
        name: 'product_details',
        query: { id }
      })
    }
  },
};
</script>

<style lang="scss">
.index-product-component-container {
  width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  .left-section {
    margin-left: 111px;
    margin-top: 93px;
    .title-line {
      width: 48px;
      height: 7px;
      background: #51a1a5;
    }
    .title-ch {
      margin-top: 11px;
      font-size: 38px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #070707;
    }
    .title-en {
      margin-top: 26px;
      font-size: 23px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #4d4d4d;
    }
    .more-button {
      cursor: pointer;
      width: 81px;
      height: 31px;
      margin-top: 59px;
      line-height: 31px;
      text-align: center;
      font-size: 11px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #51a1a5;
      border: 1px solid #51a1a5;
    }
  }
  .right-section {
    width: 737px;
    height: 496px;
    margin-top: 94px;
    margin-right: 111px;
    display: flex;
    .product-left-container {
      .option {
        display: flex;
        background: #fafafa;
        .img {
          width: 366px;
          height: 248px;
          overflow: hidden;
          background-size: 100%;
          background-position: center;
          transition: all 1s
        }
        .info {
          position: relative;
          width: 118px;
          height: 248px;
          overflow: hidden;
          .info-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 0px;
            height: 248px;
            background: #fafafa;
            transition: all 1s;
          }
          .info-title {
            text-align: center;
            margin-top: 22px;
            position: relative;
            font-size: 15px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #2c2e30;
          }
          .info-tip {
            padding: 0 13px;
            margin-top: 19px;
            position: relative;
            font-size: 15px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #7d7c7c;
          }
        }
        &:hover {
          .img {
            width: 366px;
            height: 248px;
            overflow: hidden;
            background-size: 110%;
            background-position: center;
            transition: all 1s
          }
          .info {
            position: relative;
            width: 118px;
            height: 248px;
            overflow: hidden;
            .info-bg {
              position: absolute;
              top: 0;
              left: 0;
              width: 118px;
              height: 248px;
              background: #51a1a5;
              transition: all 1s;
            }
            .info-title {
              text-align: center;
              margin-top: 22px;
              position: relative;
              font-size: 15px;
              font-family: Adobe Heiti Std;
              font-weight: normal;
              color: #fff;
            }
            .info-tip {
              padding: 0 13px;
              margin-top: 19px;
              position: relative;
              font-size: 15px;
              font-family: Adobe Heiti Std;
              font-weight: normal;
              color: #fff;
            }
          }
        }
      }
    }
    .product-right-container {
      width: 253px;
      background: #fafafa;
      .img {
        width: 253px;
        height: 371px;
        overflow: hidden;
        background-size: 100%;
        background-position: center;
        transition: all 2s
      }
      .info {
        position: relative;
        padding-top: 21px;
        width: 253px;
        height: 125px;
        overflow: hidden;
      }
      .info-bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 0px;
        height: 104px;
        // overflow: hidden;
        background: #fafafa;
        transition: all 1s;
      }
      .info-title {
        padding: 0 21px;
        font-size: 15px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #2c2e30;
        line-height: 11px;
      }
      .info-tip {
        padding: 0 21px;
        font-size: 15px;
        margin-top: 19px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #7d7c7c;
        // line-height: 10px;
      }
      &:hover {
        .img {
          width: 253px;
          height: 371px;
          overflow: hidden;
          background-size: 105%;
          background-position: center;
          transition: all 2s
        }
        .info {
          padding-top: 21px;
          position: relative;
        }
        .info-bg {
          position: absolute;
          left: 0;
          top: 0;
          width: 253px;
          height: 125px;
          padding-top: 21px;
          overflow: hidden;
          background: #51a1a5;
          transition: width 1s;
        }
        .info-title {
          position: relative;
          padding: 0 21px;
          // margin-top: 21px;
          font-size: 15px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #fff;
          line-height: 11px;
        }
        .info-tip {
          position: relative;
          padding: 0 21px;
          margin-top: 19px;
          font-size: 15px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #fff;
          // line-height: 10px;
        }
      }
    }
  }
}
</style>